import { Box, Input, InputGroup, InputLeftElement } from "@chakra-ui/react"
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  Suggestion,
} from "use-places-autocomplete"
import { useCart } from "~/hooks/useCart"
import { usePage } from "~/hooks/usePage"
import { useProcessActions } from "~/hooks/useProcessActions"
import { LatLng } from "~/interfaces/entities/LatLng"
import { RootState } from "~/redux/store"
import StoreSearchInputActionIcon from "./../StoreSearchInputActionIcon"
import StoreSuggestions from "./../StoreSuggestions"

interface Props {}

const StoreSearchInputGoogle: React.FC<Props> = () => {
  const process = useProcessActions()
  const { cart, cartActions } = useCart()

  const onPlaceSelect = useCallback(
    async ({ latLng, place }: { latLng: LatLng; place: string }) => {
      cartActions.setStoreLatLng(latLng)
      cartActions.setStorePlace(place)
      process.searchStores({})
    },
    [cartActions, process]
  )

  const selectedPlace = cart.storePlace

  return <SearchInput onSelect={onPlaceSelect} selectedPlace={selectedPlace} />
}

interface SearchInputProp {
  selectedPlace: string
  onSelect: ({ latLng, place }: { latLng: LatLng; place: string }) => void
}

const SearchInput: React.FC<SearchInputProp> = React.memo(
  ({ selectedPlace = "", onSelect }) => {
    const {
      page: {
        territories: { pickup: pagePickupTerritories },
      },
    } = usePage()
    const { t } = useTranslation()
    const [displayValue, setDisplayValue] = useState(selectedPlace)
    const inputRef = useRef<HTMLInputElement>(null!)
    const isSearchingStore = useSelector<RootState, boolean>(
      state => state.stores.isLoading
    )

    const {
      value,
      suggestions: { loading, data },
      clearSuggestions,
      setValue,
    } = usePlacesAutocomplete({
      requestOptions: {
        componentRestrictions: {
          country: pagePickupTerritories,
        },
      },
      debounce: 1000,
      defaultValue: selectedPlace,
    })

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value)
      },
      [setValue]
    )

    const selectPlace = useCallback(
      async (selected: Suggestion) => {
        setValue(selected.description, false)
        clearSuggestions()

        const geoCoded = await getGeocode({ address: selected.description })
        const latLng = await getLatLng(geoCoded[0])

        onSelect({ latLng, place: selected.description })
      },
      [clearSuggestions, onSelect, setValue]
    )

    // value change this comes from the input field
    useEffect(() => {
      setDisplayValue(value)
    }, [value])

    // selected place change, this comes from redux
    useEffect(() => {
      setDisplayValue(selectedPlace)
    }, [selectedPlace])

    const onDisplayValueClear = useCallback(() => {
      setValue("")
      setDisplayValue("")
      clearSuggestions()
      inputRef.current.focus()
    }, [clearSuggestions, setValue])

    return (
      <InputGroup>
        <Input
          id="p-search-by-address" // GTM: search by address
          ref={inputRef}
          pl={8}
          pr={2}
          borderColor="gray.200"
          name="location"
          placeholder={t(
            "components.store-search.google.StoreSearchInputGoogle.Location"
          )}
          onChange={handleOnChange}
          value={displayValue}
          isDisabled={isSearchingStore}
          autoComplete="off"
        />
        <InputLeftElement
          px={2}
          py={0}
          width="auto"
          fontSize="1.2em"
          children={
            <StoreSearchInputActionIcon
              isLoading={loading || isSearchingStore}
              isEmpty={displayValue === ""}
              onClear={onDisplayValueClear}
            />
          }
        />
        {data.length > 0 && (
          <Box
            borderWidth={1}
            borderRadius="lg"
            borderColor="gray.200"
            position="absolute"
            top="45px"
            right={0}
            left={0}
            zIndex={10}
            boxShadow="md"
          >
            <StoreSuggestions suggestions={data} onSelect={selectPlace} />
          </Box>
        )}
      </InputGroup>
    )
  }
)

export default React.memo(StoreSearchInputGoogle)
