import {
  Button,
  ButtonProps,
  Flex,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react"
import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { BiCurrentLocation } from "react-icons/bi"
import { useSelector } from "react-redux"
import InfoModal from "~/components/InfoModal"
import { useCart } from "~/hooks/useCart"
import { useMyGeolocation } from "~/hooks/useMyGeolocation"
import { useProcessActions } from "~/hooks/useProcessActions"
import { LatLng } from "~/interfaces/entities/LatLng"
import { RootState } from "~/redux/store"
import { usePrinticularGeocoder } from "./hooks/usePrinticularGeocoder"

interface Props extends ButtonProps {}

const StoreSearchUseMyLocationButtonAWS: React.FC<Props> = props => {
  const process = useProcessActions()
  const {
    isOpen: isLocationErrorModalOpen,
    onOpen: openLocationErrorModal,
    onClose: closeLocationErrorModal,
  } = useDisclosure()

  const isSearchingStore = useSelector<RootState, boolean>(
    state => state.stores.isLoading
  )
  const [isLoading, setIsLoading] = useState(false)
  const { getGeocode } = usePrinticularGeocoder()
  const { findMe } = useMyGeolocation()
  const { cartActions } = useCart()
  const { t } = useTranslation()
  const handleFindMe = useCallback(async () => {
    setIsLoading(true)
    try {
      const location = await findMe()
      const latLng: LatLng = {
        lat: location?.coords.latitude ?? 0,
        lng: location?.coords.longitude ?? 0,
      }

      const place = await getGeocode(latLng)
      if (place) {
        cartActions.setStoreLatLng(latLng)
        cartActions.setStorePlace(place)
        process.searchStores({})
      }
    } catch (error) {
      openLocationErrorModal()
    } finally {
      setIsLoading(false)
    }
  }, [cartActions, findMe, getGeocode, openLocationErrorModal, process])

  const leftIcon = isLoading ? (
    <Flex alignItems="center">
      <Spinner size="sm" />
    </Flex>
  ) : (
    <BiCurrentLocation size={20} />
  )

  return (
    <>
      <Button
        id="p-use-location" // GTM: find location geo lookup
        leftIcon={leftIcon}
        width="full"
        colorScheme="primary"
        onClick={handleFindMe}
        isDisabled={isSearchingStore}
        {...props}
      >
        {t(
          "components.store-search.aws.StoreSearchUseMyLocationButtonAWS.Location"
        )}
      </Button>
      <InfoModal
        title={t(
          "components.store-search.aws.StoreSearchUseMyLocationButtonAWS.LocationNotFound"
        )}
        description={t(
          "components.store-search.aws.StoreSearchUseMyLocationButtonAWS.Description"
        )}
        onClose={closeLocationErrorModal}
        isOpen={isLocationErrorModalOpen}
      />
    </>
  )
}

export default StoreSearchUseMyLocationButtonAWS
